import React from "react";

// Customizable Area Start
import { Box, Button, Container, Dialog, DialogActions, DialogTitle, Grid, Hidden, InputAdornment, Paper, TextField, Typography, DialogContent, styled, CircularProgress, Snackbar, IconButton, BoxProps, Select, MenuItem } from "@material-ui/core";
import {   buttonCancel, emailLogo, headerLogo, lockLogo } from "../../customisableuserprofiles/src/assets";
import { rightLogo,sponcer,languageLogo, dropdownLogo } from "../../../blocks/email-account-registration/src/assets";
import { DropdownLogo, backDrop,LanguageLogo, boxheaderLogo  } from "./assets";
import {sideLogo} from "../../email-account-login/src/assets"
import { Visibility, VisibilityOff } from "@material-ui/icons";
// Customizable Area End

import ForgotPasswordController, {
    Props
} from "./ForgotPasswordController";


export default class ForgotPassword extends ForgotPasswordController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    Logo = () => {
        return (
            <Grid item xs={12}>
                <Box className="form-logo">
                    <img src={boxheaderLogo} alt="boxheader-logo" />
                </Box>
            </Grid>
        )
    }

    Terms = () => {
  
        return (
            <Grid item xs={12}>
                <Box className="form-tnc">
                    <Typography className="form-termsgroup">
                        {this.state.languageSwitch.tnc}
                        <Typography className="form-terms" ><Typography className="form-terms" style={{cursor:"pointer"}} component={'span'} id="handleTermsBtn" onClick={()=>this.handleTermsClick()} >{this.state.languageSwitch.terms}</Typography> 
                            <Typography className="form-termsgroup" component={"span"}> {this.state.languageSwitch.and} </Typography>
                            <Typography className="form-terms" component={'span'} id="handlePrivacyBtn" style={{cursor:"pointer"}}  onClick={()=>this.handlePrivacyClick()}>{this.state.languageSwitch.prp}</Typography></Typography>
                            {this.state.languageSwitch.last}
                    </Typography>
                   
                </Box>
            </Grid>
        )
    }
    termsDialog = () => {
      
        const BoxView = Box as React.FC<BoxProps>
        const { termsAndConditions } = this.state;
        return (
            <Dialog
                scroll="body"
                fullWidth
                className="termContainer"
               
                maxWidth={'lg'}
                open={this.state.showTerms}
                aria-labelledby="responsive-dialog-title" >
                <TermsDialogStyle>
                    <DialogActions>
                        <IconButton id="cancel-terms"  onClick={() => this.setState({showTerms : false})} >
                            <svg xmlns="http://www.w3.org/2000/svg"  height="24" className="icon icon-tabler icon-tabler-playstation-x"  width="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 21a9 9 0 0 0 9 -9a9 9 0 0 0 -9 -9a9 9 0 0 0 -9 9a9 9 0 0 0 9 9z" /><path d="M8.5 8.5l7 7" /><path d="M8.5 15.5l7 -7" /></svg>
                        </IconButton>
                    </DialogActions>
                    <DialogTitle >
                        <Typography className='termsText'>{this.state.languageSwitch.terms}</Typography>
                    </DialogTitle>
                    <DialogContent >
                        <BoxView className="box">
                            <div dangerouslySetInnerHTML={{ __html: termsAndConditions }} className="dialogContent"  />
                        </BoxView>
                    </DialogContent>
                </TermsDialogStyle>
            </Dialog>
        )
    }

    privacyDialog = () => {
       
        const BoxView = Box as React.FC<BoxProps>
        const { privacyPolicy } = this.state
        return (
            <Dialog
                scroll="body"
               
                className="privacyContainer"
                fullWidth
                maxWidth={'lg'}
                open={this.state.showPrivacy}
                aria-labelledby="responsive-dialog-title" >
                <PrivacyDialogStyle>
                    <DialogActions>
                        <IconButton id="cancel-privacy" onClick={() => this.setState({showPrivacy:false})}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-playstation-x" width="24"  viewBox="0 0 24 24" height="24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 21a9 9 0 0 0 9 -9a9 9 0 0 0 -9 -9a9 9 0 0 0 -9 9a9 9 0 0 0 9 9z" /><path d="M8.5 8.5l7 7" /><path d="M8.5 15.5l7 -7" /></svg>
                        </IconButton>
                    </DialogActions>
                    <DialogTitle >
                        <Typography className='privacyText'>{this.state.languageSwitch.prp}</Typography>
                    </DialogTitle>
                    <DialogContent >
                        <BoxView className="box">
                            <div dangerouslySetInnerHTML={{ __html: privacyPolicy }} className="dialogContent"  />
                        </BoxView>
                    </DialogContent>
                </PrivacyDialogStyle>
            </Dialog>
        )
    }

    language = () => {
        return (
      
            <Grid>
                <Box style={{marginTop: '33px', textAlign: 'center' }}>
                  <div style={{display:"flex",alignContent:"center",justifyContent:"center"}}>
                    <img  width={20}  src={languageLogo} height={20} alt="Language-Logo" style={{ verticalAlign: 'top' }}   />
                    <Typography component={"span"} className="languageTxt"  >{this.state.languageSwitch.language}
                        <Select
                  value={this.state.selectedLanguage}
                  style={{
                    marginLeft: '10px',
                    borderBottom: 'none',
                    verticalAlign: 'middle',
                  }}
                  IconComponent={() => null}
                  onClose={this.handleToggleLanguageMode}
                  onChange={this.handleLanguageChange}
                  className="englishtxt"
                  open={this.state.languageMode}
                  displayEmpty
                >
                  <MenuItem value="en">English</MenuItem>
                  <MenuItem value="de">German</MenuItem>
                  <MenuItem value="fr">French</MenuItem>
                  <MenuItem value="es">Spanish</MenuItem>
                </Select>
                            <img src={dropdownLogo} alt="dropdown-logo" onClick={this.handleToggleLanguageMode} id="dropdownLogo"    style={{marginLeft: '4px',  verticalAlign: 'middle', cursor: 'pointer' }} />
                    </Typography>
                    </div>
                </Box>
            </Grid>
        )
      }
    Dialog = () => {
        const { password, confirmPassword, confirmPasswordError, passwordError } = this.state;
        return (
            <Dialog
                open={this.state.open}
                onClose={() => this.handleClose()}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogStyle>
                    <DialogActions>
                        <img src={buttonCancel} alt="button-cancel-logo"
                            data-test-id="cancel-password"
                            onClick={() => this.handleClose()} style={{ cursor: 'pointer' }} />
                    </DialogActions>
                    <DialogTitle >
                        <Typography className="dialog-title">{this.state.languageSwitch.resetPass}</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Typography className="dialog-para">
                            {this.state.languageSwitch.resetPassMsg}
                        </Typography>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                id="password"
                                type={this.state.showPasswordForget ? 'text' : 'password'}                                
                                value={password}
                                onChange={(e) => { this.handlePasswordChange(e.target.value) }}
                                placeholder={this.state.languageSwitch.EnterNewPassPh}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {password.length > 0 ? (
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => this.handleClickShowPasswordForget()}
                                                    >
                                                        {this.state.showPasswordForget ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            ) : null}
                                        </InputAdornment>
                                    ),
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img src={lockLogo} />
                                        </InputAdornment>
                                    ),
                                    className: "newPswd"
                                }}
                                inputProps={{ 'data-test-id': "pass-word" }}
                                error={passwordError !== ''}
                            />
                            <Typography className="passwordError">{this.state.passwordError}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                placeholder={this.state.languageSwitch.confirmNewPassPh}
                                value={confirmPassword}
                                variant="outlined"
                                id="cPassword"
                                onChange={(e) => this.handleConfirmPasswordChange(e.target.value)}
                                type={this.state.showConfirmPasswordForget ? 'text' : 'password'}
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {confirmPassword.length > 0 ? (
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => this.handleClickShowConfirmPasswordForget()}
                                                    >
                                                        {this.state.showConfirmPasswordForget ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            ) : null}
                                        </InputAdornment>
                                    ),
                                    className: "ConfirmNewPswd",
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img src={lockLogo} />
                                        </InputAdornment>
                                    ),

                                }}
                                inputProps={{ 'data-test-id': "confirm-password" }}
                                helperText={confirmPasswordError}
                                error={confirmPasswordError !== ''}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="outlined"
                                data-test-id="change-pswd"
                                className="change-password-btn"
                                disabled={this.state.isLoading}
                                fullWidth onClick={() => this.submitPassWord()}>
                                {this.state.isLoading ? <CircularProgress size={30} style={{ color: "#A046FB" }} /> : `${this.state.languageSwitch.createPasswordBtn}`}
                            </Button>
                        </Grid>
                    </DialogContent>

                </DialogStyle>
            </Dialog>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start        
        const { emailScreen, otpScreen, passwordSuccess, emailError, email, otp, timerActive } = this.state;
        return (
            <>
                <ModalStyle>
                    <Paper className="paper-container" elevation={0} >
                    <Box style={{ position: 'sticky', left: 0, top:0, right: 0 }}>
                            <header className="header">
                                <Container maxWidth="lg">
                                    <Grid item className="registrationLogo" >
                                        <img src={headerLogo} id="headerlogoId" alt="headerLogonew" style={{cursor:"pointer"}} width={145} onClick={this.navigateToLogin} />
                                        <img src={sponcer} style={{ marginLeft: "8px", cursor:"pointer" }} onClick={this.navigateToLogin} />
                                    </Grid>
                                </Container>
                            </header>
                        </Box>
                        <Container maxWidth="lg">
                            <Grid className="main" container alignItems="center">
                                <Hidden smDown>
                                    <Grid item md={6} xs={12}>
                                        <Typography id="headertext" className="textPara">{this.state.languageSwitch.pageHeader}</Typography>
                                        <img alt="side-logo" src={sideLogo} />
                                    </Grid>
                                </Hidden>
                                {emailScreen && <Grid container item xs={12} md={6} justifyContent="center">
                                    <Grid item xs={10} >
                                        <Box className="box">
                                            <Grid spacing={3} container>
                                                {this.Logo()}
                                                <Grid xs={12} item>
                                                    <Box className="form-logo">
                                                        <Typography className="pleaseText">{this.state.languageSwitch.forgotPassLoginMsg}</Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid xs={12} item className="emailPlaceholder" >
                                                    <TextField
                                                        onChange={(e) => this.handleEmailChange(e.target.value)}
                                                        id="email"
                                                        style={{fontFamily:"Jost"}}
                                                        variant="outlined"
                                                        fullWidth
                                                        placeholder={this.state.languageSwitch.emailInputPlaceholder}
                                                        InputProps={{
                                                            style: { height: 49, fontFamily:"Jost" },
                                                            endAdornment: (
                                                                <InputAdornment position="start">
                                                                    <img src={emailError === false ? rightLogo : ""} className="right-logo" />
                                                                </InputAdornment>
                                                            ),
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <img src={emailLogo} />
                                                                </InputAdornment>
                                                            ),
                                                            className:"loginEmail"
                                                        }}
                                                        error={emailError}
                                                        className="emailPlaceholder"
                                                        value={email}
                                                        inputProps={{ "data-test-id": "email-address" }}
                                                        helperText={!emailError ? '' : this.state.emailErrorText}
                                                    />

                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Button variant="outlined"
                                                        id="verifyemail"
                                                        data-test-id="verify"
                                                        className="continueBtn"
                                                        disabled={this.state.isLoading}
                                                        fullWidth onClick={() => { this.handleEmailSubmit() }}>
                                                        {this.state.isLoading ? <CircularProgress size={30} style={{ color: "#A046FB" }} /> : `${this.state.languageSwitch.verifyBtn}`}
                                                    </Button>
                                                </Grid>
                                                {this.Terms()}
                                            </Grid>
                                        </Box>
                                        {this.language()}
                                        
                                    </Grid>
                                </Grid>}
                                {otpScreen && <Grid container item md={6} xs={12} justifyContent="center" >
                                    <Grid item xs={10} >
                                        <Box className="box">
                                            <Grid className="form-content" container spacing={3} >
                                                {this.Logo()}
                                                <Grid item xs={12}>
                                                    <Box className="form-logo">
                                                        <Typography className="enter-otp">
                                                           {this.state.languageSwitch.setPasswordMsg}{" "}
                                                            <Typography className="edit-email" component={"span"} onClick={this.handleEditEmailAddress}>
                                                               {this.state.languageSwitch.editEmailMsg}
                                                            </Typography>
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        variant="outlined"
                                                        placeholder={this.state.languageSwitch.emailInputPlaceholder}
                                                        fullWidth
                                                        disabled
                                                        value={email}
                                                        className="disabledemail"
                                                        InputProps={{
                                                            style: { height: 49, backgroundColor:"#f4f4f4", border:"1px solid #e6e6e6", fontFamily:"Inter" },
                                                           className : "disabledemail",
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <img src={emailLogo} />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                    <Box className="timer"> {this.displayTime()}</Box>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        InputProps={{
                                                            className: "otp",
                                                        }}
                                                        variant="outlined"
                                                        onChange={(e) => this.handleOtpChange(e)}
                                                        value={otp}
                                                        id="otpInput"
                                                        fullWidth
                                                        placeholder="000000"
                                                        inputProps={{ "data-test-id": 'otp-input' }}
                                                        onKeyDown={(e) => this.handleOtpChange(e)}
                                                        error={!this.state.validOtp || !!this.state.otpError} 
                                                        helperText={
                                                            !this.state.validOtp
                                                                    ? `${this.state.languageSwitch.wrongOtpEr}`
                                                                        : this.state.otpError
                                                            }
                                                            FormHelperTextProps={{
                                                                className: "errorhelper-text"
                                                            }}
                                                        
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Box className="form-otp">
                                                        <Typography className="didnot-got">
                                                            {this.state.languageSwitch.didNotGetOtp}
                                                            <Typography component="span" 
                                                            className={`resend-otp ${(timerActive || this.state.buttonDisabled) ? 'active' : 'inactive'}`}
                                                            onClick={this.handleResendOtp}
                                                            >
                                                               {this.state.languageSwitch.resendOtp}
                                                            </Typography>
                                                            <Snackbar
                                                                open={this.state.openSnackbar}
                                                                onClose={this.handleSnackbarClose}
                                                                message={this.state.languageSwitch.OTPResent}
                                                                anchorOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'right',
                                                                }}
                                                                autoHideDuration={3000}
                                                                action={
                                                                    <Button size="small" color="secondary" onClick={this.handleSnackbarClose}>
                                                                        <img src={buttonCancel} alt="cancelBtn" />
                                                                    </Button>
                                                                }
                                                            />
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Button
                                                        disabled={this.state.isLoading || this.state.buttonDisabled}
                                                        className="submitBtn" fullWidth
                                                        data-test-id="submit-button"
                                                        variant="outlined"
                                                        id="submitOtpScreen"
                                                        onClick={() => this.handleClickOpen()}>
                                                        {this.state.isLoading ? <CircularProgress style={{ color: "#A046FB" }} size={30} /> : `${this.state.languageSwitch.submitBtn}`}
                                                    </Button>
                                                    {this.Dialog()}
                                                </Grid>
                                                {this.Terms()}
                                            </Grid>
                                        </Box>
                                        {this.language()}
                                      
                                    </Grid>
                                </Grid>}

                                {passwordSuccess && (<Grid container item md={6} xs={12} justifyContent="center" >
                                    <Grid item xs={10} >
                                        <Box className="box">
                                            <Grid spacing={3} container >
                                                {this.Logo()}
                                                <Grid item xs={12}>
                                                    <Box className="form-logo">
                                                        <Typography className="form-welcome">{this.state.languageSwitch.welcomeMsg}</Typography>
                                                        <Typography className="form-created">{this.state.languageSwitch.passResetMsg} </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Button className="loginPswrdBtn" variant="outlined" onClick={() => this.loginNavigationButton()} fullWidth>{this.state.languageSwitch.loginWithPass}</Button>
                                                </Grid>
                                                {this.Terms()}
                                            </Grid>
                                        </Box>
                                        {this.language()}
                                      
                                    </Grid>
                                </Grid>)}
                            </Grid>
                        </Container>
                    </Paper>
                    {this.termsDialog()}
                    {this.privacyDialog()}
                </ModalStyle>
            </>
        );
        // Customizable Area End

    }
}




// Customizable Area Start

const ModalStyle = styled(Box)({
    "& .header": {
        backgroundColor: "#fff",
        padding: '30px 0',
    },
    "& .paper-container": {
        backgroundImage: `url(${backDrop})`,
        height: "100vh",
        overflowX: 'hidden',
        overflow:"hidden"
    },
    "& .main": {
        height: "calc(100vh - 200px)",
    },
    "& .pleaseText": {
        fontWeight: 400,
        textAlign: "center",
        fontFamily: "Inter",
        color: "#767E85",
        fontSize: "14px",
        fontStyle: "normal",
        width:"70%",
        margin:"auto",
        fontFeatureSettings: "'clig' off, 'liga' off",
        "@media (max-width: 600px)": { 
            width: "90%",
          }
    },
    "& .paper": {
        textAlign: 'center',
        padding: 2,
    },
    "& .box": {
        padding: 30,
        boxShadow: "0px 16px 32px 0px rgba(48, 49, 51, 0.10)",
        borderRadius: "10px",
        alignItems: 'center',
        background: "#FFF",
        border: "2px solid #A046FB",
        fontFamily:"Jost"
    },
    "& .textPara": {
        fontStyle: "normal",
        marginBottom: '100px',
        textTransform: "capitalize",
        fontFamily: "Jost",
        marginLeft: '20px',
        fontSize: "30px",
        fontWeight: 700,
        color: "#0F2220",
    },
    "& .form-logo": {
        color: "#767E85",
        textAlign: "center",
    },
    "& .emailPlaceholder": {
        color: "#0F2220",
        fontSize: "30px",
        fontFamily: "'Jost', sans-serif",
        '&$important': { 
          fontFamily: "'Jost', sans-serif !important", 
        },
        "@media (max-width: 600px)": { 
            fontSize: "15px",
          }
    },
    "& .form-tnc": {
        textAlign: 'center',
    },
    "& .continueBtn": {
        height: 50,
        marginTop: 97,
        borderRadius: "4px",
        textTransform: 'capitalize',
        background: "#FFF",
        border: "2px solid #A046FB",
        fontFamily: "Jost",
        fontWeight: 'bolder',
        fontSize: "16px",
        color: "#0F2220",
        boxShadow: "0px 16px 32px 0px rgba(48, 49, 51, 0.10)",
    },
    "& .form-terms": {
        fontWeight: 400,
        fontFamily: 'Jost',
        fontSize: '12px',
        color: "#A046FB",
    },
    "& .form-termsgroup": {
        fontFamily: 'Jost',
        fontWeight: 400,
        fontSize: '12px',
        color: "#2B2B2B",
    },
    "& .englishTxt": {
        fontStyle: "normal",
        fontSize: "14px",
        fontWeight: "bold",
        color: "#A046FB",
        marginLeft: '4px',
        fontFamily: "Inter",
    },
    "& .languageTxt": {
        marginLeft: '4px',
        fontFamily: "Inter",
        fontSize: "14px",
        color: "#000",
        fontWeight: "bold",
        fontStyle: "normal",
    },
    "& .enter-otp": {
        fontStyle: "normal",
        textAlign: "center",
        fontSize: "14px",
        color: "#767E85",
        fontWeight: 400,
        fontFamily: "Inter",
        "@media (max-width: 600px)": { 
            fontSize: "13px",
          }
    },
    "& .form-content": {
        textAlign: 'center'
    },
    "& .edit-email": {
        fontWeight: 700,
        color: "#A046FB",
        fontFamily: "Inter",
        fontStyle: "normal",
        fontSize: "14px",
        cursor:"pointer",
        "@media (max-width: 600px)": { 
            fontSize: "13px",
          }
    },
    "& .otp": {
        marginTop:'-20px',
        height: "49px",
        fontFamily: "Jost",
        fontSize: "16px",
        "@media (max-width: 600px)": { 
            fontSize: "15px",
          }
    },
    "& .otp .MuiInputBase-input": {
        height : "2px !important"
    },
    "& .errorhelper-text" :{
        "@media (max-width: 600px)": { 
            fontSize: "12px",
          } 
    },
    "& .disabledemail": {
        fontFamily: "Inter",
        fontSize: "17px",
        color: "var(--grey-base, #939393)",
        "@media (max-width: 600px)": { 
            fontSize: "15px",
          }
    },
    "& .form-otp": {
        marginTop:'-20px',
        textAlign: 'end',
    },
    "& .resend-otp.active": {
        color: "#767E85 !important",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 600,
        marginLeft: '6px',
        cursor: 'pointer',
        "@media (max-width: 600px)": { 
            fontSize: "12px",
          }
    },
    "& .resend-otp.inactive": {
        fontWeight: 600,
        marginLeft: '6px',
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        color: "#A046FB !important",
        "@media (max-width: 600px)": { 
            fontSize: "12px",
          }
    },
    "& .resend-otp": {
        fontWeight: 600,
        marginLeft: '6px',
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        color: "#767E85 !important",
        cursor: 'pointer',
        "@media (max-width: 600px)": { 
            fontSize: "12px",
          }
    },
    "& .didnot-got": {
        fontFamily: "Inter",
        fontSize: "14px",
        color: "#767E85",
        fontWeight: 400,
        fontStyle: "normal",
        "@media (max-width: 600px)": { 
            fontSize: "12px",
          }
    },
    "& .submitBtn": {
        borderRadius: "4px",
        height: 50,
        textTransform: 'capitalize',
        fontWeight: 'bolder',
        background: "#FFF",
        fontFamily: "Jost",
        boxShadow: "0px 16px 32px 0px rgba(48, 49, 51, 0.10)",
        fontSize: "16px",
        color: "#0F2220",
        border: "2px solid #A046FB",
    },
    "& .timer": {
        fontSize: "14px",
        fontFamily: "Inter",
        textAlign: 'end',
        fontStyle: "normal",
        fontWeight: 400,
        color: "#7D7D7D",
        marginTop:'10px'
    },
    "& .form-created": {
        marginTop: '16px',
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 400,
        fontStyle: "normal",
        color: "#767E85",
    },
    "& .form-welcome": {
        fontSize: "20px",
        fontWeight: "bolder",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Inter",
        color: "#535655",
        fontStyle: "normal",
    },
    "& .loginPswrdBtn": {
        boxShadow: "0px 16px 32px 0px rgba(48, 49, 51, 0.10)",
        borderRadius: "2px",
        fontWeight: 'bold',
        border: "2px solid #A046FB",
        background: "#A046FB",
        fontFamily: 'Jost',
        marginTop: '140px',
        color: '#fff',
        textTransform: 'capitalize',
        height: '48px',
    },
    "& .loginEmail": {
        fontWeight: 400,
        marginTop: '20px',
        fontSize: "16px",
        color: "var(--grey-base, #939393)",
        fontStyle: "normal",
        fontFamily: "Jost",
        "@media (max-width: 600px)": { 
            fontSize: "15px",
          }
    },
    "& .loginEmail .MuiInputBase-input": {
        height : "2px !important"
     },
    "& .right-logo": {
        "@media (max-width: 600px)": { 
         marginRight:"-14px"
        }
    },
    "& .welcome": {
        fontStyle: "normal",
        fontWeight: 600,
        fontFamily: "Inter",
        fontSize: "20px",
        color: "#535655",
    },
    "& .loginPswd": {
        fontWeight: 400,
        fontStyle: "normal",
        fontFamily: "Jost",
        fontSize: "16px",
        color: "var(--grey-base, #939393)",
    },
    "& .btn-grid": {
        textAlign: "center"
    },
    "& .forgetPswd": {
        cursor: 'pointer',
        fontWeight: 500,
        color: "#A046FB",
        fontFamily: "Inter",
        fontStyle: "normal",
        textAlign: 'end',
        fontSize: "12px",
    },
    "& .loginBtn": {
        border: "2px solid #A046FB",
        width: '90%',
        boxShadow: "0px 16px 32px 0px rgba(48, 49, 51, 0.10)",
        color: '#fff',
        fontFamily: 'Inter',
        borderRadius: "2px",
        background: "#A046FB",
        height: '48px',
        textTransform: 'capitalize',
    }
})
const TermsDialogStyle = styled((Box as React.FC<BoxProps>))({

    "& .MuiDialogContent-root": {
        padding: '8px 40px'
    },
    "& .MuiDialogTitle-root": {
        padding: '0 40px'
    },
    "& .termContainer": {
        width: '100%',
        maxWidth: '1100px',
    },
   
   
    " & .termsText": {
        color: "#000",
        fontSize: "28px",
        fontStyle: "normal",
        fontFeatureSettings: "'clig' off, 'liga' off",
        lineHeight: "38px",
        fontFamily: "Jost",
       
        fontWeight: 700,
    },
    "& .dialogContent": {
        fontSize: "14px",
        color: "#3D3C3C",
        fontWeight: 400,
        fontStyle: "normal",
        lineHeight: "26px",
        fontFamily: "Inter",
        
    },
   
    "& .dialogContentPara": {
        fontFamily: "Inter",
        paddingTop: '20px',
        color: "#3D3C3C",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "26px",
        fontStyle: "normal",
        marginBottom: '10px'
    },
    "& .box": {
        // paddingTop: '60px',
    },
})

const PrivacyDialogStyle = styled((Box as React.FC<BoxProps>))({
    "& .MuiDialogContent-root": {
        padding: '8px 40px'
    },
    "& .MuiDialogTitle-root": {
        padding: '0 40px'
    },
    "& .privacyContainer": {
        width: '100%',
        maxWidth: '1100px',
    },
    " & .privacyText": {
        lineHeight: "38px",
        fontStyle: "normal",
        fontWeight: 700,
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Jost",
        color: "#000",
        fontSize: "28px",
      
    },
  
   
    "& .dialogContent": {
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 400,
        color: "#3D3C3C",
        fontStyle: "normal",
        lineHeight: "26px"
    },
  

    "& .dialogContentPara": {
        color: "#3D3C3C",
        fontFamily: "Inter",
        paddingTop: '20px',
        fontSize: "14px",
        lineHeight: "26px",
        marginBottom: '10px',
        fontStyle: "normal",
        fontWeight: 400,

    },
    "& .box": {
        // paddingTop: '60px',
    },



})
const DialogStyle = styled(Box)({
    "& .passwordError" : {
        maxWidth:"349px",
        fontSize:"12px",
        color:"#F44336",
        fontFamily:"Inter",
        marginTop:"5px",
        marginLeft:"14px"
    },
    "& .dialog-title": {
        fontSize: "28px",
        color: "#000",
        fontWeight: "bolder",
        fontFamily: "Jost",
    },
    "& .dialog-para": {
        fontStyle: "normal",
        width: '90%',
        fontWeight: 400,
        color: "#5E5E5E",
        fontFamily: "Jost",
        fontSize: "16px",
    },
    "& .change-password-btn": {
        borderRadius: "4px",
        fontFamily: 'Jost',
        textTransform: 'capitalize',
        marginTop: '32px',
        fontSize: '14px',
        fontWeight: 'bolder',
        marginBottom: '40px',
        height: '50px',
        border: "2px solid #A046FB",
        background: "#FFF",
        boxShadow: "0px 16px 32px 0px rgba(48, 49, 51, 0.10)",
        color: '#000',
    },
    "& .ConfirmNewPswd": {
        fontFamily: 'Jost',
        scrollSnapMarginTop: '20px',
        height: '49px',
        marginTop: '20px',
    },
    "& .newPswd": {
        marginTop: '24px',
        height: '49px',
        fontFamily: 'Jost',
    },
})
// Customizable Area End
