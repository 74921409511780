Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";

exports.btnExampleTitle = "CLICK ME";
exports.getStudyApiCallIdEndPoint = "bx_block_catalogue/studies/show_study";
exports.getApiMethod = "GET";
exports.getAllPatientsApi = "bx_block_catalogue/patients_studies"
exports.getAllPatientsDataApi = "bx_block_dashboard/dashboards/graph"
exports.getSinglePatientDataApi = "bx_block_dashboard/dashboards/patient_detail?account_id="
exports.getRewardsPointApi = "bx_block_dashboard/dashboards/point_reward_graph?account_id="
exports.getAllMilestoneShowApiEndPoint = "bx_block_catalogue/studies/all_milestone_show/"
exports.getFeedbackApiCallEndPoint = "bx_block_content_management/task_list_feedbacks?patient_id="
exports.getSponsorGraphDataEndPoint = '/bx_block_dashboard/sponsor_graph_data?study_id='

exports.translations = {
  fr: {
    Study_Number : "Numéro d'étude",
    Site_Coordinator : "Coordinateur de site",
    Site_Country : "Pays du site",
    Points: "Points",
    Milestone_Details : "Détails des étapes clés",
    Site_Details : "Détails du site",
    End_Date : "Date de fin",
    Dashboard : "Tableau de bord",
    Site_ID : "Identifiant du site",
    Milestone : "Étape clé",
    Total: "Total",
    Duration: "Durée",
    Site_Address : "Adresse du site",
    Start_Date : "Date de début",
    Current_Milestone : "Etape actuelle",
    Patients : "Patients",
    InactivePatients: 'Patients inactifs',
    Patients_Stats : "Statistiques des patients",
    AttendedPatients : "Patients suivis en fonction de l'âge",
    PercentageOfPatients : "Pourcentage de patients",
    TaskSubmission : "Soumission de tâche",
    NoDataFound : "Aucune donnée trouvée",
    Completed : "Complété",
    RecentlyAdded : "Patients récemment ajoutés",
    NoPatientsFound : "Aucun dossier patient n’a été ajouté à afficher.",
    PatientId : " Identifiant du patient",
    PointsEarned : "Points gagnés",
    TotalPoints : "Total des points",
    EarnedPoints : "Points gagnés",
    RewardsEarned : "Récompenses gagnées",
    TaskList : "Liste des tâches",
    Attended : "Participé",
    TaskAssigned : "Assigner une tâche",
    TaskAttended : "Tâche accomplie",
    VisitSchedule : "Programme des visites",

    DashboardDescription : "Bienvenue sur votre tableau de bord ! Affichez les détails de l’étude, la progression des détails des étapes et les informations sur le site en un seul endroit.",
    Current: "Actuelle",
    DetailOf: "Détails de",
    Site: "Site",

    TL: "TL",
    patientDescription : "Bienvenue sur la page Patients. Vous pouvez afficher et gérer les informations sur les patients, suivre la progression de la liste des tâches et afficher les patients individuels dans l'étude.",
    TotalPatients : "Total des patients",
    NotAttended : "Pas participé",
    
    TotalMilestones: "Total des jalons",
    CompletedMilestones: "Étapes clé complétées",
    PatientProgress : "Progrès du patient par rapport à chaque étape",
    SrNo : "Sr Non.",
    PatientID : "Identifiant du patient",
    patientReportPageDescription : "Bienvenue sur la page des rapports des patients. Ici, vous trouverez des détails tels que les points gagnés, les récompenses échangées, la progression de la liste de tâches, les étapes franchies, les horaires de visite et les commentaires sur l'étude de chaque patient sélectionné.",
    Earned : "Gagné",
    Redeemed : "Racheté",
    TotalEarned : "Total gagné",
    TotalRedeemed : "Total racheté",
    TaskListInformation : "Informations sur la liste des tâches",
    Feedback: "Retour",
    DateTime : "Date/heure",
    Rating : "Notation",
    Remarks : "Remarques",
    StudyNotFound: "Commentaires sur l'étude introuvables.",
    TrailAttended : "Participé à l'essai",
    Back : "Dos",
    month: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],

    showless : " montrer moins",
    showmore : " afficher plus...",
    Submitted: "Eingereicht",
    NotSubmitted : "Non soumis",
    AttendedTrials :'Participé aux essais',
    TotalPatient: 'Total des patients',
    TotalVisits: 'Visites totales',
    Scheduled: 'Programmé',
    Reports: 'Rapports',
    CommingSoon: 'comming soon',
    MilestoneStatistics: 'Statistiques des jalons',
    MilestonesCompleted: 'Étapes franchies',
    MilestonesPending : 'Jalons en attente',
    TaskStatistics: 'Statistiques des tâches',
    TotalTasks: 'Tâches totales',
    TasksCompleted: 'Tâches terminées',
    TasksPending: 'Tâches en attente',
    TotalPatientDes: '*Nombre total de patients inscrits.',
    PatientDes:'*Nombre de patients ayant créé un profil',
    InactivePatientDes: "*Nombre de patients dont le profil n'est pas créé.",
    CompletedDes: '*Nombre de patients ayant franchi toutes les étapes.',
    AverageFeedback: 'Commentaires moyens',
    FeedbackNotAvailable: 'Commentaires non disponibles',
    ActivePatients : "Patients actifs",
  },
  en: {
    Study_Number : "Study Number",
    Site_Coordinator : "Site Coordinator",
    Site_Country : "Site Country",
    Points: "Points",
    Milestone_Details : "Milestone Details",
    Site_Details : "Site Details",
    End_Date : "End Date",
    Dashboard : "Dashboard",
    Site_ID : "Site ID",
    Milestone : "Milestone",
    Total: "Total",
    Duration: "Duration",
    Site_Address : "Site Address",
    Start_Date : "Start Date",
    Current_Milestone : "Current Milestone",
    Patients : "Patients",
    InactivePatients: 'Inactive Patients',
    Patients_Stats : "Patients Statistics",
    AttendedPatients : "Attended Patient according age",
    PercentageOfPatients : "Percentage of Patients",
    TaskSubmission : "Task Submission",
    NoDataFound : "No data found",
    Completed : "Completed",
    RecentlyAdded : "Recently Added Patients",
    NoPatientsFound : "No patient records have been added to display.",
    PatientId : " Patient ID",
    PointsEarned : "Points Earned",
    TotalPoints : "Total Points",
    EarnedPoints : "Earned Points",
    RewardsEarned : "Rewards Earned",
    TaskList : "Task List",
    Attended : "Attended",
    TaskAssigned : "Task Assigned",
    TaskAttended : "Task Attended",
    VisitSchedule : "Visit Schedule",

    DashboardDescription : "Welcome to your dashboard! View study details, milestone details progress, and site information all in one place.",
    Current: "Current",
    DetailOf: "Detail of",
    Site: "Site",

    TL: "TL",
    patientDescription : "Welcome to the Patients Page, You can view and manage patient information, track progress for the task list, and view individual patients in the study.",
    TotalPatients : "Total Patients",
    NotAttended : "Not Attended",
    
    TotalMilestones: "Total Milestones",
    CompletedMilestones: "Completed Milestones",
    PatientProgress : "Patient Progress against each milestone",
    SrNo : "Sr.No",
    PatientID : "Patient ID",
    patientReportPageDescription : "Welcome to the Patient Reports Page. Here, you’ll find details such as points earned, rewards redeemed, task list progress, milestone achievements, visit schedules, and study feedback for individual selected patient.",
    Earned : "Earned",
    Redeemed : "Redeemed",
    TotalEarned : "Total Earned",
    TotalRedeemed : "Total Redeemed",
    TaskListInformation : "Task List Information",
    Feedback: "Feedback",
    DateTime : "Date/Time",
    Rating : "Rating",
    Remarks : "Remarks",
    StudyNotFound: "Study feedback not found.",
    TrailAttended : "Trial Attended",
    Back : "Back",
    month: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],

    showless : " show less",
    showmore : " show more...",
    Submitted: "Submitted",
    NotSubmitted : "Not Submitted",
    AttendedTrials :'Attended Trials',
    TotalPatient: 'Total Patients',
    TotalVisits: 'Total Visits',
    Scheduled: 'Scheduled',
    Reports: 'Reports',
    CommingSoon: 'comming soon',
    MilestoneStatistics: 'Milestone Statistics',
    MilestonesCompleted: 'Milestones Completed',
    MilestonesPending : 'Milestones Pending',
    TaskStatistics: 'Task Statistics',
    TotalTasks: 'Total Tasks',
    TasksCompleted: 'Tasks Completed',
    TasksPending: 'Tasks Pending',
    TotalPatientDes: '*Total no of patients enrolled.',
    PatientDes:'*No. of patients who created profile.',
    InactivePatientDes: '*No of Patients whose profile are not created.',
    CompletedDes: '*No of patients who completed all milestones.',
    AverageFeedback: 'Average Feedback',
    FeedbackNotAvailable: 'Feedback not available',
    ActivePatients : "Active Patients",
  },
  es: {
    Study_Number : "Número de estudio",
    Site_Coordinator : "Coordinador lateral",
    Site_Country : "País del sitio",
    Points: "Puntos",
    Milestone_Details : "Detalles del hito",
    Site_Details : "Detalles del sitio",
    End_Date : "Fecha de finalización",
    Dashboard : "Panel",
    Site_ID : "Identificación del sitio",
    Milestone : "Hito",
    Total: "Total",
    Duration: "Duración",
    Site_Address : "Dirección del sitio",
    Start_Date : "Fecha de inicio",
    Current_Milestone : "Hito actual",
    Patients : "Pacientes",
    InactivePatients: 'Pacientes inactivos',
    Patients_Stats : "Estadísticas de pacientes",
    AttendedPatients : "Pacientes atendidos por edad",
    PercentageOfPatients : "Porcentaje de pacientes",
    TaskSubmission : "Envío de tareas",
    NoDataFound : "No se encontraron datos",
    Completed : "Terminado",
    RecentlyAdded : "Pacientes recientemente agregados",
    NoPatientsFound : "No se han agregado registros de pacientes para mostrar.",
    PatientId : "Identificación del paciente",
    PointsEarned : "Puntos ganados",
    TotalPoints : "Puntos totales",
    EarnedPoints : "Puntos ganados",
    RewardsEarned : "Recompensas ganadas",
    TaskList : "Lista de tareas",
    Attended : "Asistió",
    TaskAssigned : "Tarea asignada",
    TaskAttended : "Tarea atendida",
    VisitSchedule : "Horario de visitas",

    DashboardDescription : "¡Bienvenido a tu panel de control! Vea los detalles del estudio, el progreso de los detalles de los hitos y la información del sitio, todo en un solo lugar.",
    Current: "Actual",
    DetailOf: "Detalles de",
    Site: "Site",

    TL: "TL",
    patientDescription : "Bienvenido a la página de pacientes. Puede ver y administrar la información del paciente, realizar un seguimiento del progreso de la lista de tareas y ver pacientes individuales en el estudio.",
    TotalPatients : "Total de pacientes",
    NotAttended : "No asistido",
    
    TotalMilestones: "Hitos totales",
    CompletedMilestones: "Hitos completados",
    PatientProgress : "Progreso del paciente en cada hito",
    SrNo : "Sr No.",
    PatientID : "Identificación del paciente",
    patientReportPageDescription : "Bienvenido a la página de Informes de pacientes. Aquí encontrará detalles como los puntos obtenidos, las recompensas canjeadas, el progreso de la lista de tareas, los logros importantes, los horarios de visitas y los comentarios del estudio para cada paciente seleccionado.",
    Earned : "Ganado",
    Redeemed : "Redimido",
    TotalEarned : "Total ganado",
    TotalRedeemed : "Total canjeado",
    TaskListInformation : "Información de la lista de tareas",
    Feedback: "Comentario",
    DateTime : "Fecha/hora",
    Rating : "Clasificación",
    Remarks : "Observaciones",
    StudyNotFound: "No se encontraron comentarios del estudio.",
    TrailAttended : "Ensayo asistido",
    Back : "Atrás",
    month: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],

    showless : " mostrar menos",
    showmore : " mostrar más...",
    Submitted: "Enviado",
    NotSubmitted : "No enviado",
    AttendedTrials :'Ensayos asistidos',
    TotalPatient: 'Total de pacientes',
    TotalVisits: 'Visitas totales',
    Scheduled: 'Programado',
    Reports: 'Informes',
    CommingSoon: 'comming soon',
    MilestoneStatistics: 'Estadísticas de hitos',
    MilestonesCompleted: 'Hitos completados',
    MilestonesPending : 'Hitos pendientes',
    TaskStatistics: 'Estadísticas de tareas',
    TotalTasks: 'Tareas totales',
    TasksCompleted: 'Tareas completadas',
    TasksPending: 'Tareas Pendientes',
    TotalPatientDes: '*Número total de pacientes inscritos.',
    PatientDes:'*No. de pacientes que crearon perfil',
    InactivePatientDes: '*No de Pacientes cuyo perfil no está creado.',
    CompletedDes: '*Número de pacientes que completaron todos los hitos.',
    AverageFeedback: 'Comentarios promedio',
    FeedbackNotAvailable: 'Comentarios no disponibles',
    ActivePatients : "Pacientes Activos",
  },
  de: {
    Study_Number : "Studiennummer",
    Site_Coordinator : "Site-Koordinator",
    Site_Country : "Standortland",
    Points: "Punkte",
    Milestone_Details : "Erfolg Informationen",
    Site_Details : "Seitendetails",
    End_Date : "Enddatum",
    Dashboard : "Hauptseite",
    Site_ID : "Site-ID",
    Milestone : "Erfolg",
    Total: "Gesamt",
    Duration: "Dauer",
    Site_Address : "Site-Adresse",
    Start_Date : "Startdatum",
    Current_Milestone : "aktueller Erfolg",
    Patients : "Patienten",
    InactivePatients: 'Inaktive Patienten',
    Patients_Stats : "Patientenstatistik",
    AttendedPatients : "Betreuer Patient nach Alter",
    PercentageOfPatients : "Percentage of Patients",
    TaskSubmission : "Aufgabenübermittlung",
    NoDataFound : "Keine Daten gefunden",
    Completed : "Vollendet",
    RecentlyAdded : "Kürzlich hinzugefügte Patienten",
    NoPatientsFound : "Es wurden keine Patientendatensätze zur Anzeige hinzugefügt.",
    PatientId : " Patienten-ID",
    PointsEarned : "Punkte verdient",
    TotalPoints : "Gesamtpunktzahl",
    EarnedPoints : "Verdiente Punkte",
    RewardsEarned : "Verdiente Belohnungen",
    TaskList : "Aufgabenliste",
    Attended : "Besucht",
    TaskAssigned : "Aufgabe zugewiesen",
    TaskAttended : "Aufgabe besucht",
    VisitSchedule : "Sehen Sie Besucher Kalender",

    DashboardDescription : "Willkommen in Ihrem Dashboard! Sehen Sie sich Studiendetails, Meilensteindetails, Fortschrittsdetails und Standortinformationen an einem Ort an.",
    Current: "Aktuell",
    DetailOf: "Einzelheiten zu",
    Site: "Site",

    TL: "TL",
    patientDescription : "Willkommen auf der Patientenseite. Sie können Patienteninformationen anzeigen und verwalten, den Fortschritt der Aufgabenliste verfolgen und einzelne Patienten in der Studie anzeigen.",
    TotalPatients : "Patienten insgesamt",
    NotAttended : "Nicht besucht",
    
    TotalMilestones: "Gesamt Erfolg",
    CompletedMilestones: "Erfolgreich Abgeschlossen",
    PatientProgress : "Fortschritte des Patient hinsichtlich jedes Meilensteins",
    SrNo : "Sr. Nr.",
    PatientID : "Patienten-ID",
    patientReportPageDescription : "Willkommen auf der Seite mit Patientenberichten. Hier finden Sie Details wie gesammelte Punkte, eingelöste Prämien, Fortschritte in der Aufgabenliste, erreichte Meilensteine, Besuchspläne und Studienfeedback für einzelne ausgewählte Patienten.",
    Earned : "Verdient",
    Redeemed : "Eingelöst",
    TotalEarned : "Insgesamt verdient",
    TotalRedeemed : "Insgesamt eingelöst",
    TaskListInformation : "Aufgabenlisteninformationen",
    Feedback: "Rückmeldung",
    DateTime : "Datum/Uhrzeit",
    Rating : "Bewertung",
    Remarks : "Bemerkungen",
    StudyNotFound: "Studienfeedback nicht gefunden.",
    TrailAttended : "Studien besucht",
    Back : "Zurück",
    month: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],

    showless : " weniger zeigen",
    showmore : " mehr anzeigen...",
    Submitted: "Eingereicht",
    NotSubmitted : "Nicht übermittelt",
    AttendedTrials :'AttendeBetreute Studien',
    TotalPatient: 'Patienten insgesamt',
    TotalVisits: 'Besuche insgesamt',
    Scheduled: 'Vereinbart',
    Reports: 'Berichte',
    CommingSoon: 'comming soon',
    MilestoneStatistics: 'Meilensteinstatistik',
    MilestonesCompleted: 'Meilensteine ​​abgeschlossen',
    MilestonesPending : 'Meilensteine ​​stehen noch aus',
    TaskStatistics: 'Aufgabenstatistik',
    TotalTasks: 'Gesamtaufgaben',
    TasksCompleted: 'Aufgaben abgeschlossen',
    TasksPending: 'Ausstehende Aufgaben',
    TotalPatientDes: '*Gesamtzahl der eingeschriebenen Patienten.',
    PatientDes:'*Anzahl der Patienten, die ein Profil erstellt haben',
    InactivePatientDes: '*Anzahl der Patienten, deren Profil nicht erstellt wurde.',
    CompletedDes: '*Anzahl der Patienten, die alle Meilensteine ​​erreicht haben.',
    AverageFeedback: 'Durchschnittliches Feedback',
    FeedbackNotAvailable: 'Feedback nicht verfügbar',
    ActivePatients : "Aktive Patienten",
  },
};

// Customizable Area End