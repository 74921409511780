import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import * as Yup from "yup";
import { imgPasswordVisible, imgPasswordInVisible } from "./assets";
import { setStorageData,getStorageData } from "framework/src/Utilities";
export const configJSONReg = require("../../email-account-registration/src/config");
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  accountType: string;
  emailSchema: any;
  phoneSchema: any;
  otpSchema: any;
  passwordSchema: any;
  accountStatus: any;
  passwordRules: any;
  emailValue: any;
  phoneValue: any;
  countryCodeSelected: any;
  token: any;
  enablePasswordField: Boolean;
  btnConfirmPasswordShowHide: Boolean;
  email: string;
  password: string;
  confirmPassword: any;
  passwordError: any;
  confirmPasswordError: any;
  open: boolean;
  otpScreen: boolean;
  emailScreen: boolean;
  emailErrorText: string;
  passwordSuccess: boolean;
  emailError: any;
  otp: any;
  validOtp: boolean;
  otpError: any;
  seconds: number;
  otpConfirm: string;
  isLoading: boolean;
  otpErrorText: string;
  openSnackbar: boolean;
  timerActive: boolean;
  showTerms: boolean;
  showPrivacy: boolean;
  showPasswordForget: boolean;
  showConfirmPasswordForget: boolean;
  termsAndConditions: any;
  privacyPolicy: any;
  attempts : number;
  errorMsg : string;
  buttonDisabled : boolean;
  languageMode : boolean,
  selectedLanguage : string,
  languageSwitch: {
    pageHeader: string,
    forgotPassLoginMsg : string,
    verifyBtn : string,
    sixDigitOtp: string,
    emailInputPlaceholder: string,
    editEmailMsg : string,
    didNotGetOtp: string,
    resendOtp: string,
    submitBtn: string,
    welcomeMsg: string,
    passResetMsg : string,
    loginWithPass : string,
    tnc: string,
    terms: string,
    last: string,
    and: string,
    prp: string,
    createPasswordBtn : string,
    language: string,
    invalidEmailErr : string,
    wrongOtpEr : string,
    otpExpiredErr : string,
    maxOtpAttemptsErr : string,
    resetPass : string,
    resetPassMsg : string,
    passwordSyntaxErr : string,
    passwordMatchErr : string,
    confirmPassErr: string,
    pepErr : string,
    EnterNewPassPh : string,
    confirmNewPassPh : string,
    setPasswordMsg: string,
    OTPResent: string,
    password15CharErr: string
  };
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  countdown: any;
  forgetEmailRegistrationAPIid: any;
  forgetEmailOTPConfirmationAPIid: any;
  forgetPasswordRegistrationAPIid: any;
  validationAPICallId: any;
  requestEmailOtpCallId: any;
  requestPhoneOtpCallId: any;
  requestChangePasswordCallId: any;
  requestGoToConfirmationCallId: any;
  otpToken: any;
  isChangePassword?: boolean;
  getTermsandConditionApiCallId: string = "";
  getPrivacyPolicyApiCallId: string = "";

  //Properties from config
  labelTextIsAccountRecovery: string = configJSON.labelTextIsAccountRecovery;
  secondLabelText: string = configJSON.secondLabelText;
  thirdLabelText: string = configJSON.thirdLabelText;
  forthLabelText: string = configJSON.forthLabelText;
  fifthLabelText: string = configJSON.fifthLabelText;
  sixthLabelText: string = configJSON.sixthLabelText;
  firstInputAutoCompleteType: any = configJSON.firstInputAutoCompleteType;
  firstInputKeyboardStyle: any = configJSON.firstInputKeyboardStyle;
  firstInputPlaceholder: string = configJSON.firstInputPlaceholder;
  firstInputErrorColor: any = configJSON.firstInputErrorColor;
  buttonTextIsNext: string = configJSON.buttonTextIsNext;
  buttonColorForNextButton: any = configJSON.buttonColorForNextButton;
  secondInputAutoCompleteType: any = configJSON.secondInputAutoCompleteType;
  secondInputKeyboardType: any = configJSON.secondInputKeyboardType;
  secondInputPlaceholder: string = configJSON.secondInputPlaceholder;
  secondInputErrorColor: any = configJSON.secondInputErrorColor;
  thirdInputPlaceholder: string = configJSON.thirdInputPlaceholder;
  thirdInputErrorColor: any = configJSON.thirdInputErrorColor;
  buttonTitleIsSMSPhoneAccount: string =
    configJSON.buttonTitleIsSMSPhoneAccount;
  buttonTitleIsEmailAccount: string = configJSON.buttonTitleIsEmailAccount;
  labelTextIsPleaseEnterYourNewPassword: string =
    configJSON.labelTextIsPleaseEnterYourNewPassword;
  labelTextIsYourPasswordHasBeenSuccessfullyChanged: string =
    configJSON.labelTextIsYourPasswordHasBeenSuccessfullyChanged;
  placeholderIsPassword: string = configJSON.placeholderIsPassword;
  imgPasswordInVisible: any = imgPasswordInVisible;
  imgPasswordVisible: any = imgPasswordVisible;
  placeholderIsReTypePassword: string = configJSON.placeholderIsReTypePassword;
  buttonTitleIsOk: string = configJSON.buttonTitleIsOk;
  buttonColorForOkButton: any = configJSON.buttonColorForOkButton;
  countryCodeSelectorPlaceholder: string =
    configJSON.countryCodeSelectorPlaceholder;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    //email schema
    let emailSchema = {
      email: Yup.string()
        .email(configJSON.pleaseEnterAValidEmail)
        .required(configJSON.emailIsRequired),
    };

    //phone schema
    let phoneSchema = {
      countryCode: Yup.number()
      .required("Country code is required"),

      phone: Yup.string()
        .matches(configJSON.phoneRegExp, configJSON.phoneNumberIsNotValid)
        .required(configJSON.phoneNumberIsRequired),
    };

    //otpSchema
    let otpSchema = {
      otpCode: Yup.number().min(4).required(configJSON.otpCodeIsRequired),
    };

    //passwordSchema
    let passwordSchema = {
      password: Yup.string()
        .required(configJSON.pleaseEnterAPassword)
        .min(2, configJSON.passwordMustBeAtLeast2Characters),
      confirmPassword: Yup.string()
        .required(configJSON.pleaseConfirmYourPassword)
        .when("password", {
          is: (value) => (value && value.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            configJSON.passwordsMustMatch
          ),
        }),
    };

    this.state = {
      accountType: "sms",
      accountStatus: "ChooseAccountType",
      emailValue: "",
      phoneValue: "",
      countryCodeSelected: "",
      passwordRules: "",
      emailSchema: emailSchema,
      phoneSchema: phoneSchema,
      otpSchema: otpSchema,
      passwordSchema: passwordSchema,
      token: "",
      enablePasswordField: true,
      btnConfirmPasswordShowHide: true,
      email: "",
      password: "",
      seconds: 60,
      open: false,
      otpScreen: false,
      emailScreen: true,
      emailErrorText: "",
      passwordSuccess: false,
      emailError: null,
      otpError: null,
      otp: null,
      validOtp: true,
      confirmPassword: "",
      passwordError: "",
      confirmPasswordError: "",
      otpConfirm: "",
      isLoading: false,
      otpErrorText: "",
      openSnackbar: false,
      timerActive: true,
      showTerms: false,
      showPrivacy: false,
      showPasswordForget: false,
      showConfirmPasswordForget: false,
      termsAndConditions: '',
      privacyPolicy: '',
      attempts : 3,
      errorMsg : "Please enter a valid 6-digit OTP",
      buttonDisabled : false,
      languageMode : false,
      selectedLanguage : "en",
      languageSwitch: {
        pageHeader: '',
        forgotPassLoginMsg : '',
        verifyBtn : '',
        sixDigitOtp: '',
        emailInputPlaceholder: '',
        editEmailMsg : '',
        didNotGetOtp: '',
        resendOtp: '',
        submitBtn: '',
        welcomeMsg: '',
        passResetMsg : '',
        loginWithPass : '',
        tnc: '',
        terms: '',
        last: "",
        and: '',
        prp: '',
        createPasswordBtn :'',
        language: '',
        invalidEmailErr :'',
        wrongOtpEr : '',
        otpExpiredErr : '',
        maxOtpAttemptsErr: '',
        resetPass : '',
        resetPassMsg: '',
        passwordSyntaxErr : '',
        passwordMatchErr : '',
        confirmPassErr : '',
        pepErr : '',
        EnterNewPassPh : '',
        confirmNewPassPh : '',
        setPasswordMsg: '',
        OTPResent: '',
        password15CharErr: ''
      }
    };
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.validationRulesRequest();
    this.getSelectedLanguage();
  }
  // Customizable Area End

  validationRulesRequest = () => {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileValidationSettingsAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    var errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (apiRequestCallId === this.forgetEmailRegistrationAPIid && response) {
      this.handleForgetEmail(response)
    }
    if (apiRequestCallId === this.forgetEmailOTPConfirmationAPIid && response) {
      this.handleEmailOTP(response)
    }
    if (apiRequestCallId === this.forgetPasswordRegistrationAPIid && response) {
      this.handlePasswordRegistration(response, errorReponse)
    }
    else if(apiRequestCallId === this.getTermsandConditionApiCallId){
      this.setState({ termsAndConditions: response.data.description });

    }
    else if(apiRequestCallId === this.getPrivacyPolicyApiCallId){
      this.setState({ privacyPolicy: response.data.description });

    }
     // Customizable Area End
  }

  startForgotPassword(accountType: String) {
    this.setState({
      accountStatus: accountType === "sms" ? "EnterPhone" : "EnterEmail",
    });
  }

  goToOtpAfterEmailValidation(values: { accountType: string; email: string }) {
    //change status to OTP
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestEmailOtpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryStartOtpAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.setState({
      emailValue: values.email ? values.email : "",
    });

    const data = {
      type: values.accountType ? values.accountType : "email_account",
      attributes: {
        email: values.email ? values.email : "",
      },
    };

    const httpBody = {
      data: data,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToOtpAfterPhoneValidation(values: { phone: string }) {
    // console.log("entered phone validation code");
    if (
      !this.state.countryCodeSelected ||
      this.state.countryCodeSelected.length === 0
    ) {
      this.showAlert(
        configJSON.goToOtpAfterPhoneValidationErrorTitle,
        configJSON.goToOtpAfterPhoneValidationErrorBody
      );
      return;
    }
    console.log(this.state.countryCodeSelected);
    //change status to OTP
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestPhoneOtpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryStartOtpAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.setState({
      phoneValue:
        this.state.countryCodeSelected && values.phone
          ? this.state.countryCodeSelected + values.phone
          : "",
    });

    const data = {
      type: "sms_account",
      attributes: {
        full_phone_number: this.state.phoneValue,
      },
    };

    const httpBody = {
      data: data,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToChangePasswordAfterOtp(values: { otpCode: string }) {
    //change status to change password
    //change status to OTP
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestChangePasswordCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryConfirmOtpAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const data = {
      token: this.otpToken ? this.otpToken : "",
      otp_code: values.otpCode ? values.otpCode : "",
    };

    const httpBody = {
      data: data,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToConfirmationAfterPasswordChange(values: {
    password: any;
    confirmPassword: any;
  }) {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestGoToConfirmationCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryChangePasswordAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const data = {
      token: this.otpToken ? this.otpToken : "",
      new_password: values.password,
    };

    const httpBody = {
      data: data,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  // Customizable Area Start
  postForgetEmailRegistration = () => {

    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
      token: this.state.token
    };

    let formDataCampaign = {
      "data": {
        "type" :"email_account",
        "attributes":{
          "email" : this.state.email.toLowerCase(),
          "role_name" : "Sponsor"
        }
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forgetEmailRegistrationAPiEndPointSendOtp
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(formDataCampaign)
    );
    this.forgetEmailRegistrationAPIid = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  postForgetEmailOTPConfirmation = () => {

    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
      token: this.state.token,
      locale:this.state.selectedLanguage
    };

    let formDataCampaignOtp = {
      "data": {
        "token": this.state.token,
        "otp_code": this.state.otp,
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forgetEmailOTPConfirmationAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(formDataCampaignOtp)
    );
    this.forgetEmailOTPConfirmationAPIid = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  postForgetPasswordRegistration = () => {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
      token: this.state.token
    };

    let formDataCampaignPassword = {
      "data": {
        "token": this.state.token,
        "new_password": this.state.password,
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forgetPasswordRegistrationAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(formDataCampaignPassword)
    );
    this.forgetPasswordRegistrationAPIid = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  async componentWillUnmount() {
    clearInterval(this.countdown);
  }

  otpTimer = () => {
    clearInterval(this.countdown);
    this.countdown = setInterval(() => {
      if (this.state.seconds > 0) {
        this.setState((prevState) => ({
          seconds: prevState.seconds - 1,
        }));
      } else {
        if (this.state.timerActive) {
          this.handleResendOtp();
          clearInterval(this.countdown);
          this.setState({ timerActive: false });
        }
      }
    }, 1000);
  }

  handleForgetEmail = (responseJson: any) => {
    if (responseJson && responseJson.errors) {
      if (responseJson.errors.message === "Account not found") {
        this.setState({ emailError: true, emailErrorText: this.state.languageSwitch.invalidEmailErr, isLoading: false })
      }
    } else {
      this.setState({ token: responseJson.data.token, emailScreen: false, otpScreen: true, isLoading: false, email:this.state.email.toLowerCase() }, () => {
        this.otpTimer()
      })
    }
  }

  handleEmailOTP = (responseJson: any) => {
    if (responseJson && responseJson.data && responseJson.data.message) {
      this.setState({ open: true, isLoading: false });
      return true;
    } else if (responseJson.error) {
      
      const { error } = responseJson;
      this.setState({ isLoading: false });
      
      if (error.remaining_count == 0) {
        return this.handleMaxAttemptsReached(error.message);
      } else {
        return this.handleOtherErrors(error.message);
      }
    }
  };
  
 handleMaxAttemptsReached = (errorMessage: string) => {
  const timerDuration = 5 * 60 * 1000; 
  const endTime = Date.now() + timerDuration;

  const updateTimer = () => {
    const remainingTime = endTime - Date.now();

    if (remainingTime <= 0) {
      this.setState({
        otpError: '',
        buttonDisabled: false,
        attempts: 3
      });
      return;
    }

    const minutesLeft = Math.floor(remainingTime / (60 * 1000));
    const secondsLeft = Math.floor((remainingTime % (60 * 1000)) / 1000); 

    const minutesDisplay = minutesLeft < 10 ? `0${minutesLeft}` : minutesLeft;
    const secondsDisplay = secondsLeft < 10 ? `0${secondsLeft}` : secondsLeft;
    const timerMessage = `${errorMessage} - ${minutesDisplay}:${secondsDisplay}.`;
    this.setState({ otpError: timerMessage });

    setTimeout(updateTimer, 1000); 
  };

  this.setState({
    buttonDisabled: true,
    isLoading: false
  });

  updateTimer(); 
};

  
  
  handleOtherErrors = (errorMessage: string) => {
    this.setState({
      otpError: errorMessage,
      isLoading: false,
      attempts: this.state.attempts - 1
    }, () => {
      if (this.state.attempts === 0) {
        this.setState({
          buttonDisabled: true
        });
        setTimeout(() => {
          this.setState({ buttonDisabled: false, attempts: 3 });
        }, 5 * 60 * 1000);
      }
    });
  };
  


  handlePasswordRegistration(responseJson: any, errorReponse: any) {
    if (!errorReponse && responseJson ) {
      this.handleSetPassword(responseJson);
    }
  }

  handleSetPassword = (responseJson: any) => {
    this.setState({ otpScreen: false, open: false, isLoading: false,  passwordSuccess: true, });
  }

  handleClose = () => {
    this.setState({
      open: false, password: "", confirmPassword: "", confirmPasswordError: '', passwordError: '', showPasswordForget: false,
      showConfirmPasswordForget: false,
    });
  };

  handlePasswordChange = (value: any) => {
    value = value.trim(' ');
    let confirmPasswordError = '';
    const strongPasswordRegex = configJSON.StrongpasswordRegex;
    if (value !== this.state.confirmPassword) {
      confirmPasswordError = this.state.confirmPasswordError;
    }
    let passwordError = '';
    if (value !== '') {
      if (value.length > 15) {
        passwordError = this.state.languageSwitch.password15CharErr;
      } 
      else {
      passwordError = strongPasswordRegex.test(value)
        ? ''
        : this.state.languageSwitch.passwordSyntaxErr;
    }
  }
    this.setState({
      confirmPasswordError,
      password: value,
      passwordError,
    });
  };

  handleConfirmPasswordChange = (value: any) => {
    this.setState({
      confirmPasswordError: value === '' || this.state.password === value ? '' : this.state.languageSwitch.passwordMatchErr,
      confirmPassword: value,
    });
  };

  submitPassWord = () => {
    if (this.state.confirmPassword === "" && this.state.password === "" ) {
      this.setState({ passwordError: this.state.languageSwitch.pepErr, confirmPasswordError: this.state.languageSwitch.confirmPassErr })
    }else if (this.state.confirmPassword === "") {
      this.setState({ confirmPasswordError: this.state.languageSwitch.confirmPassErr })
    } else if (this.state.password === "") {
      this.setState({ passwordError: this.state.languageSwitch.passwordMatchErr})
    } else if (this.state.password !== this.state.confirmPassword) {
      this.setState({ confirmPasswordError: this.state.languageSwitch.passwordMatchErr })
    } 
    else if (this.state.confirmPasswordError === "" && this.state.passwordError === "") {
      this.postForgetPasswordRegistration();
      this.setState({
        isLoading: true
      })
    }
  }

  loginNavigationButton = () => {
    this.props.navigation.navigate("EmailAccountLoginBlock")
  }

  handleEmailChange = (value: string) => {
    const regex = configJSON.emailRegex
    if (value.trim() === '') {
      this.setState({ emailError: null, emailErrorText: '' });
    } else if (value.match(regex)) {
      this.setState({ emailError: false, emailErrorText: '' });
    } else {
      this.setState({
        emailError: true,
        emailErrorText: this.state.languageSwitch.invalidEmailErr,
      });
    }
  
    this.setState({ email: value });
  }

  handleEmailSubmit = () => {
    if (this.state.emailError === false) {
      this.postForgetEmailRegistration()
      this.setState({ isLoading: true })
    } else {
      this.setState({ emailError: true, emailErrorText: this.state.languageSwitch.invalidEmailErr})
    }
  }

  displayTime() {
    const minutes = Math.floor(this.state.seconds / 60);
    const remainingSeconds = this.state.seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  }

  handleOtpChange = (event: any) => {
    const input = event.target.value;
    this.setState({otpError:""})
    const otpc = input.replace(/\D/g, ''); 
    
    if (otpc.length > 6) {
      
      this.setState({
        otp: otpc.slice(0, 6),
        validOtp: true
      });
    } else {
      this.setState({
        otp: otpc,
        validOtp: otpc.length === 6 || otpc.length === 0 
      });
    }
  };

  handleClickOpen = () => {
    if (this.state.otp === null || this.state.otp.length !== 6) {
      this.setState({ validOtp: false })
    } 
    else {
      this.setState({ validOtp: true })
    }
   
      this.postForgetEmailOTPConfirmation();
      this.setState({
        isLoading: true
      })
    
  };

  handleResendOtp = () => {
    if (!this.state.buttonDisabled && !this.state.timerActive) {
      this.postForgetEmailRegistration();
      this.setState({ openSnackbar: true, seconds: 60, timerActive: true, attempts : 3, buttonDisabled : false });
      this.otpTimer();
    }
  };

  handleSnackbarClose = (reason: any) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ openSnackbar: false });
  };

  handleEditEmailAddress = () => {
    clearInterval(this.countdown);
    this.setState({
      timerActive: true, emailScreen: true, validOtp: true, otpScreen: false, otp: null, seconds: 60,
    })
  }
  handleClosePrivacy = () => {
    this.setState({ showPrivacy: false })
  }
  handleTermsClick = () => {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTermsandConditionApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTermsAndConditionApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ showTerms: true })
  };
  handleCloseTerms = () => {
    this.setState({ showTerms: false })
  }
  handlePrivacyClick = () => {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPrivacyPolicyApiCallId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getNewPrivacyPolicyApiEndPoint
    );

   

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ showPrivacy: true })
  }
  handleClickShowPasswordForget = () => {
    this.setState((prevState) => ({
      showPasswordForget: !prevState.showPasswordForget
    }))
  }
  handleClickShowConfirmPasswordForget = () => {
    this.setState((prevState) => ({
      showConfirmPasswordForget: !prevState.showConfirmPasswordForget
    }))
  }
  navigateToLogin=()=>{
    const navigateToLoginMessage: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    navigateToLoginMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(navigateToLoginMessage);
  }
  handleToggleLanguageMode = ()=>{
    this.setState({languageMode : !this.state.languageMode})
}
handleLanguageChange = (event: React.ChangeEvent<{ value: unknown }>) => {
  const newLanguage = event.target.value as string;
  const translations = configJSONReg.translations[newLanguage];

  this.setState({
    selectedLanguage: newLanguage,
    languageSwitch: {
      pageHeader: translations.pageHeader,
      forgotPassLoginMsg : translations.forgotPassLoginMsg,
      verifyBtn : translations.verifyBtn,
      sixDigitOtp: translations.sixDigitOtp,
      emailInputPlaceholder: translations.emailInputPlaceholder,
      editEmailMsg : translations.editEmailMsg,
      didNotGetOtp: translations.didNotGetOtp,
      resendOtp: translations.resendOtp,
      submitBtn: translations.submitBtn,
      welcomeMsg: translations.welcomeMsg,
      passResetMsg : translations.passResetMsg,
      loginWithPass : translations.loginWithPass,
      tnc: translations.tnc,
      terms: translations.terms,
      last: translations.last,
      and: translations.and,
      resetPass : translations.resetPass,
      prp: translations.prp,
      pepErr : translations.pepErr,
      resetPassMsg : translations.resetPassMsg,
      createPasswordBtn :translations.createPasswordBtn,
      language: translations.language,
      invalidEmailErr : translations.invalidEmailErr,
      wrongOtpEr : translations.wrongOtpEr,
      otpExpiredErr : translations.otpExpiredErr,
      maxOtpAttemptsErr: translations.maxOtpAttemptsErr,
      passwordSyntaxErr : translations.pEBoxText,
      passwordMatchErr: translations.passwordMatchErr,
      confirmPassErr: translations.confirmPassErr,
      EnterNewPassPh : translations.EnterNewPassPh,
      confirmNewPassPh : translations.confirmNewPassPh,
      setPasswordMsg: translations.setPasswordMsg,
      OTPResent: translations.OTPResent,
      password15CharErr: translations.password15CharErr
      
    }
    
  },()=>{this.handleEmailChange(this.state.email)});
  

  setStorageData('lang', newLanguage);
};
  
  getSelectedLanguage = async () => {
    let selLanguage = await getStorageData('lang');
    this.setState({ selectedLanguage: selLanguage }, () => {
      this.getInitialValues();
    });
  };
  getInitialValues = ()=>{
    const translations = configJSONReg.translations[this.state.selectedLanguage];
    
    this.setState({
      languageSwitch: {
        pageHeader: translations.pageHeader,
        emailInputPlaceholder: translations.emailInputPlaceholder,
        editEmailMsg : translations.editEmailMsg,
        didNotGetOtp: translations.didNotGetOtp,
        forgotPassLoginMsg : translations.forgotPassLoginMsg,
        verifyBtn : translations.verifyBtn,
        sixDigitOtp: translations.sixDigitOtp,
        maxOtpAttemptsErr: translations.maxOtpAttemptsErr,
        resendOtp: translations.resendOtp,
        tnc: translations.tnc,
        invalidEmailErr : translations.invalidEmailErr,
        terms: translations.terms,
        last: translations.last,
        passwordMatchErr: translations.passwordMatchErr,
        and: translations.and,
        prp: translations.prp,
        EnterNewPassPh : translations.EnterNewPassPh,
        createPasswordBtn :translations.createPasswordBtn,
        language: translations.language,
        passwordSyntaxErr : translations.pEBoxText,
        wrongOtpEr : translations.wrongOtpEr,
        submitBtn: translations.submitBtn,
        confirmNewPassPh : translations.confirmNewPassPh,
        otpExpiredErr : translations.otpExpiredErr,
        welcomeMsg: translations.welcomeMsg,
        passResetMsg : translations.passResetMsg,
        loginWithPass : translations.loginWithPass,
        resetPass : translations.resetPass,
        confirmPassErr: translations.confirmPassErr,
        resetPassMsg : translations.resetPassMsg,
        pepErr : translations.pepErr,
        setPasswordMsg: translations.setPasswordMsg,
        OTPResent: translations.OTPResent,
        password15CharErr: translations.password15CharErr
      }
  });
}
  // Customizable Area End
}
